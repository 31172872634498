import Input from '@/interfaces/inputs/Input'
import Textarea from '@/interfaces/inputs/Textarea'
import Checkbox from '@/interfaces/inputs/Checkbox'
import FormElement from '@/interfaces/form/FormElement'

const name = (): Input => ({
  id: 'company',
  label: 'Compañia',
  type: 'text',
  value: null,
  required: true
})

const url = (): Input => ({
  id: 'url',
  label: 'Url',
  type: 'text',
  value: null,
  required: true
})

const image = (): Input => ({
  id: 'image',
  label: 'Logo',
  type: 'file',
  value: null
})

const position = (): Input => ({
  id: 'position',
  label: 'Posicion',
  type: 'text',
  value: null,
  required: false
})
const location = (): Input => ({
  id: 'location',
  label: 'Localizacion',
  type: 'text',
  value: null,
  required: false
})

const description = (): Textarea => ({
  id: 'description',
  label: 'Descripcion',
  value: null,
  required: false
})

const startDate = (): Input => ({
  id: 'startDate',
  label: 'Fecha de inicio',
  type: 'date',
  value: null,
  required: true
})

const current = (): Checkbox => ({
  id: 'current',
  label: 'Actualmente estudiando',
  type: 'checkbox',
  value: false
})

const finishDate = (): Input => ({
  id: 'finishDate',
  label: 'Fecha de finalizacion',
  type: 'date',
  value: null,
  required: true
})

export const formElements = (): FormElement[] => [
  {
    type: 'input',
    data: name()
  },
  {
    type: 'input',
    data: url()
  },
  {
    type: 'input',
    data: image()
  },
  {
    type: 'input',
    data: position()
  },
  {
    type: 'input',
    data: location()
  },
  {
    type: 'input',
    data: description()
  },
  {
    type: 'input',
    data: startDate()
  },

  {
    type: 'input',
    data: current()
  },
  {
    type: 'input',
    data: finishDate(),
    isVisible: (formElements: FormElement[]) => {
      return (
        formElements.find(element => element.data.id === 'current').data
          .value === false
      )
    }
  }
]
